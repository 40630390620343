export const sliderItems = [
	{
		id: 1,
		img: "https://www.apple.com/newsroom/images/2023/09/apple-unveils-iphone-15-pro-and-iphone-15-pro-max/article/Apple-iPhone-15-Pro-lineup-color-lineup-geo-230912_big.jpg.large_2x.jpg",
		title: "iPhone 15 Pro",
		desc: "EXPERIENCE THE FUTURE. UPGRADE TO iPHONE 15 PRO TODAY.",
		bg: "333333",
		textColor: "ffffff",
		link: "/products/iphone-15-pro",
	},
	{
		id: 2,
		img: "https://ipadizate.com/hero/2022/07/AirPods-MAX.1656930087.7593.jpg?width=1200",
		title: "AirPods Max",
		desc: "IMMERSIVE SOUND. GET YOUR AIRPODS MAX TODAY.",
		bg: "e3e3e3",
		textColor: "000000",
		link: "/products/airpods-max",
	},
	{
		id: 3,
		img: "https://techcentral.co.za/wp-content/uploads/2018/09/apple-watch-series-4-2156-1120.jpg",
		title: "Apple Watch Series 9",
		desc: "YOUR PERFECT FITNESS PARTNER. BUY APPLE WATCH SERIES 9.",
		bg: "333333",
		textColor: "ffffff",
		link: "/products/apple-watch-series-9",
	},

	{
		id: 4,
		img: "https://www.zdnet.com/a/img/resize/30b76c1c7613f46b99f0b32567fdfb78b6c86836/2024/02/28/686425fa-536e-4429-8a3c-e8423506f1dc/ipad-air-with-pencil.jpg?auto=webp&width=1280",
		title: "iPad Air",
		desc: "CREATIVE. PORTABLE. POWERFUL. DISCOVER iPAD AIR.",
		bg: "e3e3e3",
		textColor: "000000",
		link: "/products/ipad-air",
	},
];

export const categories = [
	{
		id: 1,
		img: "https://i.ebayimg.com/00/s/MTAyNFg4NjY=/z/eh0AAOSweTldj1Rp/$_86.JPG",
		title: "iPhones",
		cat: "iPhones",
	},
	{
		id: 2,
		img: "https://usa.hollysale.com/wp-content/uploads/2020/12/MyImage1608889729800Image.jpg",
		title: "Used iPhones",
		cat: "UiPhones",
	},
	{
		id: 3,
		img: "https://th.bing.com/th/id/R.78965e9f83cca9cc5b1695b3a50c6440?rik=jy43Ei9w2cQXuA&riu=http%3a%2f%2ffntcase.com%2fcdn%2fshop%2farticles%2fe95f5a0899797c6a16bdaa13b803f683_ccd6dad2-5395-46ae-9883-b851c0a85428.jpg%3fv%3d1704356004&ehk=u2sQKt%2f3ApF5zFDhGnJWJjFP%2b%2fY1ktLgzenXJRmJBXE%3d&risl=&pid=ImgRaw&r=0",
		title: "Accessories",
		cat: "accessories",
	},
];

export const popularProducts = [
	{
		id: 1,
		img: "https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
	},
	{
		id: 2,
		img: "https://cdn.shopify.com/s/files/1/0101/4832/products/Angela_Natural_Tee.png?v=1606780388",
	},
	{
		id: 3,
		img: "https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
	},
	{
		id: 4,
		img: "https://www.burdastyle.com/pub/media/catalog/product/cache/7bd3727382ce0a860b68816435d76e26/107/BUS-PAT-BURTE-1320516/1170x1470_BS_2016_05_132_front.png",
	},
	{
		id: 5,
		img: "https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
	},
	{
		id: 6,
		img: "https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
	},
	{
		id: 7,
		img: "https://www.vintageindustries.nl/download_front/qympzk1762/2217_Arrow_Jacket_Forest.png",
	},
	{
		id: 8,
		img: "https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
	},
];

// data.js
export const reviews = [
	{
		id: 1,
		author: "Supun Chathuranga",
		review: "Highly recommended Apple Mart🔥😍❤️",
		date: "2024-07-02",
		rating: 5,
		photo:
			"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiOk_2xm_fbYDnisXaXzqyI7uRhtGkk4moEMs-WbJVdUaY_YAj6uf_T6wcIB84pUly2KHKHRk8iSrnyyhNJ_Bz7xqZUYfQean1GhSjWG09qLQTbAFV5IScgL1BM1BbzncgDOzjc8Si8GQcmVSg_6Wo1FQI6a0KkeLJDSl76Sx8kMps26W-fblWPnDcXnqlh/s320/supun%20chaturanga.jpg", // Add the photo URL here
	},
	{
		id: 2,
		author: "Nipun Imalka",
		review: "I phone නම් ඉතින් Apple mart තමයි",
		date: "2024-08-16",
		rating: 5,
		photo:
			"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgYqtsDs_O0QGOU-8-j8zBBAZDwYhyphenhyphen-NrazcMWjtAdLf12jYpncw-owNGj6Qirks6n42o3M-B2gwTM2BHXFbgqwlr7KA9UVbSbSkk1hhW0VUgEv0VmHYE7m0m-OLDOnCbytjHVswauQQrqopNejyFIr7f4cCkxfK63fj4gVYPgKksvmYIPQT5VRDegoa-H2/w317-h320/Nipun%20Imalka.jpg", // Add the photo URL here
	},
	{
		id: 3,
		author: "Sanduni Kularathna",
		review:
			"Thank you Aiya for the support given from the very first and given a high quality product.Highly recommended.",
		date: "2024-08-17",
		rating: 5,
		photo:
			"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgo0VlqORRjYgvyxyMN7Q4qwyDcjIJil8cNADldOS-hfGJ1I74T36rhuIu6r7rQJ8pplgP38PbWNuUhzdGibt7mMHvJQr8MD3aGOdLHwj6bG84-ucwdf99TBs-ckxCi_YzvXxHPcv24wZbK-rPBYFLwEPQDYzblqb8ZLBetEqsDLcMYhuFa8qJvnEdq_lCM/s320/Sadani%20Karunarathna.jpg", // Add the photo URL here
	},
	{
		id: 4,
		author: "Kasuni Jayalath",
		review: "Highly recommend 🔥",
		date: "2024-08-18",
		rating: 4,
		photo:
			"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhdwalhy_1Uj1qQFXynE7a5xI7SpX2jROwwqhkLxFV4RqOQ0b7D5wd8utZyJy5JjyJI9ks2QYCfoKSCGuokpeX8-u2guPS5KBol33lfjWVwb2WfkKnUWbeRRO1CQ2JPva-Ox_o6RFVs8rhZxddxRWgP-KebouT9JvZMZ-M39P-DC560o7VJqnxp_QdTz5w_/s320/Kasuni.jpg", // Add the photo URL here
	},
	{
		id: 5,
		author: "Chamod Korale",
		review: "Highly recommend 💗🥂",
		date: "2024-08-19",
		rating: 4,
		photo:
			"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjClflCbFRVozTGqQRum0MvUY2pWAc8so-G9HRCMeCO0Kg1M4H0tdZ-hVMnVX8WzDaiMkQkF1jzJ2aoI9DatHK2QUIHjDshc150o8nxF_zIFNOQRn9Fcl_K5_JTLH3XY-S4AgBwLvNAZwyOpqg_CtokxAO-qJ_56H8Rrp3tuGiryRp3Uh2fAfCRLTk5Jg9C/s320/Chamod%20Korala.jpg", // Add the photo URL here
	},
	{
		id: 6,
		author: "Dushan Chamika",
		review: "Thank you Apple Mart❤️❤️ Highly Recommend",
		date: "2024-08-20",
		rating: 5,
		photo:
			"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEht3KYsRAOUACq4mBx9yG4MCIGJKA_pQUcb0rddIfqODABQPKPpMBGg87b7ovhomrdq0iThb7GWnfX6lY0FVqKec61vEHvUOh-1-gS0fPZEONkSVOsrBitvZxBhAgKgTLRqnUtv1dmudSD4rRTwW8mhyphenhyphenlGe7YqC8GpzYyfo5HLUQ9W3UO7W10JUb2pu5Stl/s320/Dasun%20Chamika.jpg", // Add the photo URL here
	},
];

// src/data.js

export const tikTokEmbeds = [
	{
		id: 1,
		embedCode: `<blockquote class="tiktok-embed" cite="https://www.tiktok.com/@applemart97/video/7186672878866173211" data-video-id="7186672878866173211" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@applemart97" href="https://www.tiktok.com/@applemart97?refer=embed">@applemart97</a> <a title="commercial" target="_blank" href="https://www.tiktok.com/tag/commercial?refer=embed">#commercial</a> <a title="applemart" target="_blank" href="https://www.tiktok.com/tag/applemart?refer=embed">#applemart</a> <a title="iphone" target="_blank" href="https://www.tiktok.com/tag/iphone?refer=embed">#iphone</a> <a title="apple" target="_blank" href="https://www.tiktok.com/tag/apple?refer=embed">#apple</a> <a title="dream" target="_blank" href="https://www.tiktok.com/tag/dream?refer=embed">#dream</a> <a title="mobile" target="_blank" href="https://www.tiktok.com/tag/mobile?refer=embed">#mobile</a> <a title="partners" target="_blank" href="https://www.tiktok.com/tag/partners?refer=embed">#partners</a> <a target="_blank" title="♬ original sound - Applemart" href="https://www.tiktok.com/music/original-sound-7186672904988412698?refer=embed">♬ original sound - Applemart</a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script>`,
	},
];
