import React from "react";
import styled from "styled-components";
import { colors } from "../theme";

const CardContainer = styled.div`
	background-color: ${colors.gray};
	width: 90%; /* Adjust width to fit inside the carousel */
	height: auto; /* Set height to auto for dynamic content */
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 20px;
	margin: 10px auto; /* Center the card */
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ReviewerName = styled.h3`
	font-size: 18px;
	color: ${colors.black};
	margin-bottom: 10px;
`;

const ReviewText = styled.p`
	font-size: 16px;
	color: ${colors.darkGray};
	margin: 0;
`;

const RatingContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
`;

const Star = styled.span`
	font-size: 20px;
	color: ${(props) => (props.filled ? "#FFD700" : "#e0e0e0")};
`;

const PhotoContainer = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	overflow: hidden; /* Ensure the image fits within the round container */
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
`;

const Photo = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover; /* Cover the entire container while maintaining the aspect ratio */
`;

const ReviewCard = ({ review }) => {
	const { author, review: reviewText, rating, photo } = review;

	return (
		<CardContainer>
			{photo && (
				<PhotoContainer>
					<Photo
						src={photo}
						alt={`${author}'s photo`}
					/>
				</PhotoContainer>
			)}
			<ReviewerName>{author}</ReviewerName>
			<RatingContainer>
				{[...Array(5)].map((_, index) => (
					<Star
						key={index}
						filled={index < rating}>
						★
					</Star>
				))}
			</RatingContainer>
			<ReviewText>{reviewText}</ReviewText>
		</CardContainer>
	);
};

export default ReviewCard;
