// src/components/Footer.jsx
import {
	Facebook,
	Instagram,
	MailOutline,
	Phone,
	Room,
} from "@material-ui/icons";
import TikTok from "../assets/icons/tiktok.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { colors } from "../theme";

// Existing styled components
const Container = styled.div`
	display: flex;
	${mobile({ flexDirection: "column" })}
	${tablet({ flexDirection: "column" })}
	background-color: ${colors.primary};
`;

const Left = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 20px;
	color: ${colors.white};
`;

const Logo = styled.h1``;

const Desc = styled.p`
	margin: 20px 0px;
	${mobile({ display: "none" })}
	${tablet({ display: "none" })}
`;

const SocialContainer = styled.div`
	margin-top: 10px;
	display: flex;
`;

const SocialIcon = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	color: white;
	background-color: #${(props) => props.color};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
`;

const Center = styled.div`
	flex: 1;
	padding: 20px;
	${mobile({ display: "none" })}
	${tablet({ display: "none" })}
	color: ${colors.white};
`;

const Title = styled.h3`
	margin-bottom: 30px;
`;

const List = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
`;

const ListItem = styled.li`
	width: 50%;
	margin-bottom: 10px;
	font-size: 14px;
	color: ${colors.white};
	cursor: pointer;
	transition: color 0.3s ease;

	&:hover {
		color: ${colors.secondary};
	}

	a {
		text-decoration: none;
		color: inherit;
		font-weight: 500;
	}

	&:before {
		content: "-";
		font-size: large;
		margin-right: 8px;
		color: ${colors.secondary};
	}
`;

const Right = styled.div`
	flex: 1;
	padding: 20px;
	${mobile({ backgroundColor: "#fff8f8" })}
	${tablet({ fontsize: "20px" })}
	color: ${colors.white};
`;

const ContactItem = styled.div`
	margin-bottom: 20px;
	display: flex;
	align-items: center;
`;

const Payment = styled.img`
	width: 50%;
`;

// New styled component for the footer text
const FooterText = styled.p`
	text-align: center;
	padding: 10px 0;
	background-color: ${colors.black};
	color: ${colors.white};
	font-size: 14px;
	font-style: italic;
`;

const Footer = () => {
	return (
		<>
			<Container>
				<Left>
					<Logo>applemart.</Logo>
					<Desc>
						Discover the latest and greatest in Apple iPhones brand new and
						gently used plus premium accessories to elevate your mobile
						experience. At applemart, we bring you unbeatable value and
						exceptional quality.
					</Desc>
					<SocialContainer>
						<a
							href="https://www.facebook.com/profile.php?id=100089640755257"
							target="_blank"
							rel="noopener noreferrer">
							<SocialIcon color="3B5999">
								<Facebook />
							</SocialIcon>
						</a>
						<a
							href="https://www.instagram.com/your-profile"
							target="_blank"
							rel="noopener noreferrer">
							<SocialIcon color="E4405F">
								<Instagram />
							</SocialIcon>
						</a>
						<a
							href="https://www.tiktok.com/@applemart97"
							target="_blank"
							rel="noopener noreferrer">
							<SocialIcon color="000000">
								<img
									src={TikTok}
									alt="Tiktok"
								/>
							</SocialIcon>
						</a>
					</SocialContainer>
				</Left>

				<Center>
					<Title>Useful Links</Title>
					<List>
						<ListItem>
							<Link to="/">Home</Link>
						</ListItem>
						<ListItem>
							<Link to="/products/iPhones">iPhones</Link>
						</ListItem>
						<ListItem>
							<Link to="/products/UiPhones">Used iPhones</Link>
						</ListItem>
						<ListItem>
							<Link to="/products/accessories">Accessories</Link>
						</ListItem>
						<ListItem>
							<Link to="/privacy-policy">Privacy Policy</Link>
						</ListItem>
						<ListItem>
							<Link to="/terms-and-conditions">Terms & Conditions</Link>
						</ListItem>
					</List>
				</Center>
				<Right>
					<Title>Contact</Title>
					<ContactItem>
						<Room style={{ marginRight: "10px" }} /> Ambalanwatta, Atakalampanna
					</ContactItem>
					<ContactItem>
						<Phone style={{ marginRight: "10px" }} />
						+94 712963335
					</ContactItem>
					<ContactItem>
						<MailOutline style={{ marginRight: "10px" }} />{" "}
						applemartambalanwatta@gmail.com
					</ContactItem>
					{/* <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" /> */}
				</Right>
			</Container>
			<FooterText>developed by moracodez</FooterText>
		</>
	);
};

export default Footer;
