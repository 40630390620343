import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ProductCard from "./ProductCard";

const Container = styled.div`
	padding: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const Products = ({ cat, filters, sort }) => {
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		const getProducts = async () => {
			try {
				const res = await axios.get(
					cat
						? `${process.env.REACT_APP_BASE_URL}/api/products?category=${cat}`
						: `${process.env.REACT_APP_BASE_URL}/api/products`
				);
				setProducts(res.data);
			} catch (err) {
				console.error("Error fetching products:", err);
			}
		};
		getProducts();
	}, [cat]);

	// useEffect(() => {
	// 	if (cat) {
	// 		setFilteredProducts(
	// 			products.filter((item) =>
	// 				Object.entries(filters).every(([key, value]) =>
	// 					item[key]?.includes(value)
	// 				)
	// 			)
	// 		);
	// 	} else {
	// 		setFilteredProducts(products);
	// 	}
	// }, [products, cat, filters]);

	useEffect(() => {
		if (cat || filters) {
			setFilteredProducts(
				products.filter((item) =>
					Object.entries(filters).every(([key, value]) => {
						if (key === "color") {
							return item.colors.some((color) => color.name === value);
						}
						if (key === "storage") {
							return item.storage.some((storage) => storage.size === value);
						}
						return item[key]?.includes(value);
					})
				)
			);
		} else {
			setFilteredProducts(products);
		}
	}, [products, cat, filters]);

	useEffect(() => {
		const sortProducts = (a, b) => {
			if (sort === "newest") {
				return new Date(b.createdAt) - new Date(a.createdAt);
			} else {
				const priceA =
					a.price || (a.storage && a.storage.length > 0 && a.storage[0].price);
				const priceB =
					b.price || (b.storage && b.storage.length > 0 && b.storage[0].price);

				if (sort === "asc") return priceA - priceB;
				if (sort === "desc") return priceB - priceA;
			}
			return 0;
		};

		if (sort) {
			setFilteredProducts((prev) => [...prev].sort(sortProducts));
		}
	}, [sort]);

	return (
		<Container>
			{filteredProducts.map((item) => (
				<ProductCard
					item={item}
					key={item.id}
				/>
			))}
		</Container>
	);
};

export default Products;
