import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { mobile, tablet, pc } from "../responsive";
import { Link } from "react-router-dom";
import LogoSVG from "../assets/logo-white.svg";
import { colors } from "../theme";

const Container = styled.div`
	${mobile({
		height: "70px",
		flexDirection: "column",
		padding: "10px 10px",
	})}
	${tablet({ height: "55px" })}
	${pc({ height: "50px" })}
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${colors.darkgray};
	position: sticky;
	top: 0;
	z-index: 1000;
	transform: translateY(${(props) => (props.isVisible ? "0" : "-100%")});
	transition: transform 0.3s ease-in-out;
`;

const Wrapper = styled.div`
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	${mobile({ flexDirection: "column", padding: "10px 0px" })}
	${tablet({ padding: "10px 15px" })}
	${pc({ padding: "10px 30px" })}
`;

const Left = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Center = styled.div`
	flex: 1;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: row;
	${mobile({ marginBottom: "10px", display: "none" })}
	${tablet({ display: "none" })}
`;

const Logo = styled.img`
	height: 40px;
	${mobile({ height: "20px" })}
	${tablet({ height: "25px" })}
	${pc({ height: "30px" })}
`;

const Right = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	${mobile({
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		margintop: "5px",
	})}
	${tablet({ flex: 1.5, justifyContent: "flex-end" })}
`;

const MenuItem = styled(Link)`
	font-size: 18px;
	cursor: pointer;
	margin-left: 25px;
	text-decoration: none;
	color: ${colors.white};
	${mobile({ fontSize: "15px", marginLeft: "10px", marginBottom: "10px" })}
	${tablet({ fontSize: "15px", marginLeft: "15px" })}

	&:hover {
		color: ${colors.secondary};
	}
`;

const Navbar = () => {
	const [isVisible, setIsVisible] = useState(true);
	let lastScrollTop = 0;

	const handleScroll = () => {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		if (scrollTop > lastScrollTop) {
			// Scrolling down
			setIsVisible(false);
		} else {
			// Scrolling up
			setIsVisible(true);
		}
		lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Container isVisible={isVisible}>
			<Wrapper>
				<Left>
					<Link to="/">
						<Logo
							src={LogoSVG}
							alt="logo"
						/>
					</Link>
				</Left>
				<Center>{/* Center content here */}</Center>
				<Right>
					<MenuItem to="/products/iPhones">iPhones</MenuItem>
					<MenuItem to="/products/UsediPhones">Used iPhones</MenuItem>
					<MenuItem to="/products/Accessories">Accessories</MenuItem>
				</Right>
			</Wrapper>
		</Container>
	);
};

export default Navbar;
