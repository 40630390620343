// src/components/FacebookEmbed.jsx
import React, { useEffect } from "react";
import styled from "styled-components";
import { colors } from "../theme";

const Wrapper = styled.div`
	/* border: 1px solid red; */
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;

	h3 {
		text-align: center;
		margin-bottom: 20px;
		color: ${colors.white};
	}
`;

const Container = styled.div`
	height: 100%;
	display: flex;
	justify-content: center; /* Center items horizontally */

	@media (max-width: 768px) {
		max-width: 100% !important; /* Ensure it fits on smaller screens */
	}

	.fb-page {
		width: 100% !important; /* Ensure the plugin uses full width of its container */
		max-width: 500px; /* Optional: set a maximum width for better control */
		border: none !important; /* Optional: remove any border */
		overflow: hidden; /* Prevent overflow issues */
	}
`;

const FacebookEmbed = () => {
	useEffect(() => {
		// Load the Facebook SDK script
		if (window.FB) {
			window.FB.XFBML.parse();
		} else {
			const script = document.createElement("script");
			script.async = true;
			script.defer = true;
			script.crossOrigin = "anonymous";
			script.src =
				"https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v20.0&appId=888114843019959";
			script.nonce = "Lmhk5dJ6";
			document.body.appendChild(script);
		}
	}, []);

	return (
		<Wrapper>
			<h3>Facebook</h3>
			<Container>
				<div
					className="fb-page"
					data-href="https://www.facebook.com/profile.php?id=100089640755257&amp;sk=photos_by"
					data-tabs="timeline"
					data-width="450"
					data-height="700"
					data-small-header="false"
					data-adapt-container-width="false"
					data-hide-cover="false"
					data-show-facepile="false">
					<blockquote
						cite="https://www.facebook.com/profile.php?id=100089640755257&amp;sk=photos_by"
						className="fb-xfbml-parse-ignore">
						<a href="https://www.facebook.com/profile.php?id=100089640755257&amp;sk=photos_by">
							Apple Mart
						</a>
					</blockquote>
				</div>
			</Container>
		</Wrapper>
	);
};

export default FacebookEmbed;
