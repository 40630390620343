// src/components/ReviewSlider.jsx
import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import styled from "styled-components";
import ReviewCard from "./ReviewCard";
import { reviews } from "../data"; // Ensure the path is correct
import { colors } from "../theme";

const StyledCarouselContainer = styled.div`
	background-color: ${colors.primary};
	padding: 20px 0;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	width: 100%;
	overflow: hidden; /* Hide any overflowing content */
`;

const ReviewSlider = () => {
	const [slidePercentage, setSlidePercentage] = useState(33);

	useEffect(() => {
		const updateSlidePercentage = () => {
			if (window.innerWidth <= 768) {
				// Mobile
				setSlidePercentage(100);
			} else if (window.innerWidth <= 1024) {
				// Tablet
				setSlidePercentage(50);
			} else {
				// Desktop
				setSlidePercentage(33);
			}
		};

		updateSlidePercentage(); // Initial check
		window.addEventListener("resize", updateSlidePercentage); // Update on resize

		return () => window.removeEventListener("resize", updateSlidePercentage);
	}, []);

	// Limit the reviews to only the first 6
	const limitedReviews = reviews.slice(0, 6);

	return (
		<StyledCarouselContainer>
			<Carousel
				infiniteLoop
				autoPlay
				interval={3000} // Change the interval as needed
				showStatus={false}
				showThumbs={false}
				showArrows={true}
				emulateTouch
				dynamicHeight={false}
				centerMode
				centerSlidePercentage={slidePercentage} // Responsive sizing
				useKeyboardArrows>
				{limitedReviews.map((review) => (
					<ReviewCard
						key={review.id}
						review={review}
					/>
				))}
			</Carousel>
		</StyledCarouselContainer>
	);
};

export default ReviewSlider;
