import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { sliderItems } from "../data";
import { mobile, tablet } from "../responsive";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Container = styled.div`
	width: 100%;
	height: 80vh;
	display: flex;
	position: relative;
	overflow: hidden;
	${mobile({ display: "none" })}
	${tablet({
		height: "60vh", // Adjust height on tablets
	})}
`;

const Arrow = styled.div`
	width: 50px;
	height: 50px;
	background-color: #fff7f7;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: ${(props) => props.direction === "left" && "10px"};
	right: ${(props) => props.direction === "right" && "10px"};
	margin: auto;
	cursor: pointer;
	opacity: 0.5;
	z-index: 2;
`;

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	transition: all 1.5s ease;
	transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	background-color: #${(props) => props.bg};
`;

const ImgContainer = styled.div`
	/* border: 1px solid red; */
	width: 50vw; /* Fixed width */
	height: 100%; /* Fixed height */
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden; /* Hide any overflow */
	${tablet({
		display: "none", // Hide on tablets
	})}
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover; /* Crop the image to fit the container */
`;

const InfoContainer = styled.div`
	/* border: 1px solid red; */
	height: 80%;
	flex: 1;
	padding: 50px;
	${tablet({
		height: "60vh",
	})}
`;

const Title = styled.h1`
	font-size: 70px;
	color: #${(props) => props.textColor};
	${tablet({
		fontsize: "20px",
	})}
`;

const Desc = styled.p`
	margin: 50px 0px;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 3px;
	color: #${(props) => props.textColor};
	${tablet({
		display: "none",
	})}
`;

const Button = styled.button`
	padding: 10px;
	font-size: 20px;
	background-color: transparent;
	cursor: pointer;
	color: #${(props) => props.textColor};
`;

const Slider = () => {
	const [slideIndex, setSlideIndex] = useState(0);

	const handleClick = (direction) => {
		if (direction === "left") {
			setSlideIndex((prev) => (prev > 0 ? prev - 1 : sliderItems.length - 1));
		} else {
			setSlideIndex((prev) => (prev < sliderItems.length - 1 ? prev + 1 : 0));
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setSlideIndex((prev) => (prev < sliderItems.length - 1 ? prev + 1 : 0));
		}, 5000); // Change slide every 5 seconds

		return () => clearInterval(interval); // Clear interval on component unmount
	}, [slideIndex]);

	return (
		<Container>
			<Arrow
				direction="left"
				onClick={() => handleClick("left")}>
				<ArrowLeftOutlined />
			</Arrow>
			<Wrapper slideIndex={slideIndex}>
				{sliderItems.map((item) => (
					<Slide
						bg={item.bg}
						key={item.id}>
						<ImgContainer>
							<Image src={item.img} />
						</ImgContainer>
						<InfoContainer>
							<Title textColor={item.textColor}>{item.title}</Title>
							<Desc textColor={item.textColor}>{item.desc}</Desc>
							<Link to={item.link}>
								<Button textColor={item.textColor}>SHOW NOW</Button>
							</Link>
						</InfoContainer>
					</Slide>
				))}
			</Wrapper>
			<Arrow
				direction="right"
				onClick={() => handleClick("right")}>
				<ArrowRightOutlined />
			</Arrow>
		</Container>
	);
};

export default Slider;
