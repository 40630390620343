import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { mobile, tablet } from "../responsive";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { publicRequest } from "../requestMethods";
import { colors as themeColors } from "../theme";

// Styled Components
const Container = styled.div``;

const Wrapper = styled.div`
	display: flex;
	padding: 50px;
	background-color: ${themeColors.primary};
	min-height: 80vh;
	height: auto;
	${mobile({ padding: "10px", flexDirection: "column" })}
	${tablet({ padding: "50px", flexDirection: "column", alignItems: "center" })}
`;

const ImageWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80%;
`;

const Image = styled.img`
	border-radius: 10px;
	width: 100%;
	height: 60vh;
	object-fit: cover;
	${mobile({ height: "40vh" })}
	${tablet({ height: "50vh", width: "50vw" })}
`;

const ThumbnailCarouselContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 20px;
`;

const ThumbnailContainer = styled.div`
	display: flex;
	overflow: hidden;
	width: calc(50px * 5 + 10px * 4); /* 5 thumbnails and 4 gaps */
	scroll-behavior: smooth;
`;

const ThumbnailList = styled.div`
	display: flex;
	transition: transform 0.3s ease;
`;

const Thumbnail = styled.img`
	width: 50px;
	height: 50px;
	object-fit: cover;
	margin: 0 5px;
	cursor: pointer;
	border: ${(props) => (props.selected ? "2px solid teal" : "none")};
`;

const CarouselButton = styled.button`
	background-color: ${themeColors.white};
	border: none;
	cursor: pointer;
	margin: 0 5px;
	padding: 5px;
	opacity: 0.7;
	transition: opacity 0.3s;
	&:hover {
		opacity: 1;
	}
`;

const InfoContainer = styled.div`
	flex: 1;
	padding: 20px;
	${mobile({ padding: "10px", marginTop: "20px", marginBottom: "20px" })}
	${tablet({ padding: "10px", marginTop: "20px", marginBottom: "20px" })}
`;

const Title = styled.h1`
	font-weight: 200;
	color: ${themeColors.white};
`;

const Desc = styled.p`
	margin: 20px 0px;
	color: ${themeColors.white};
`;

const Price = styled.span`
	font-weight: 100;
	font-size: 40px;
	color: ${themeColors.white};
`;

const SelectStorage = styled.select`
	padding: 10px;
	display: flex;
	margin-top: 20px;
	background-color: ${themeColors.primary};
	color: ${themeColors.white};
	font-size: 16px;
	border-radius: 5px;
`;

const Option = styled.option`
	color: ${themeColors.gray};
`;

// Product Component
const Product = () => {
	const location = useLocation();
	const id = location.pathname.split("/")[2];
	const [product, setProduct] = useState(null);
	const [selectedImage, setSelectedImage] = useState("");
	const [loading, setLoading] = useState(true);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [selectedStorage, setSelectedStorage] = useState(null);
	const [selectedColor, setSelectedColor] = useState(null); // State for selected color

	const thumbnailListRef = useRef(null);

	useEffect(() => {
		const getProduct = async () => {
			try {
				const res = await publicRequest.get("/api/products/find/" + id);
				setProduct(res.data);

				const allImages = [
					...res.data.img.map((img) => ({ url: img, isColorImage: false })),
					...(res.data.colors || []).map((color) => ({
						url: color.photo,
						isColorImage: true,
						colorName: color.name, // Assuming color name is stored in 'name'
					})),
				];

				// Set initial selected image and color
				if (allImages.length > 0) {
					setSelectedImage(allImages[0].url);
					if (res.data.colors && res.data.colors.length > 0) {
						setSelectedColor(res.data.colors[0].name);
					}
				}

				if (res.data.storage && res.data.storage.length > 0) {
					setSelectedStorage(res.data.storage[0]);
				}

				setLoading(false);
			} catch (err) {
				console.error("Failed to fetch product:", err);
				setLoading(false);
			}
		};
		getProduct();
	}, [id]);

	if (loading) {
		return <div>Loading...</div>;
	}

	if (!product) {
		return <div>Product not found.</div>;
	}

	const allImages = [
		...product.img.map((img) => ({ url: img, isColorImage: false })),
		...(product.colors || []).map((color) => ({
			url: color.photo,
			isColorImage: true,
			colorName: color.name,
		})),
	];

	const visibleThumbnails = 5;

	const scrollThumbnails = (direction) => {
		let newIndex = currentIndex;

		if (direction === "left" && currentIndex > 0) {
			newIndex -= 1;
		} else if (
			direction === "right" &&
			currentIndex < allImages.length - visibleThumbnails
		) {
			newIndex += 1;
		}

		setCurrentIndex(newIndex);
		if (thumbnailListRef.current) {
			thumbnailListRef.current.style.transform = `translateX(-${
				newIndex * 60
			}px)`;
		}
	};

	const handleStorageChange = (e) => {
		const selectedSize = Number(e.target.value);
		const selectedStorageOption = product.storage.find(
			(storage) => storage.size === selectedSize
		);
		setSelectedStorage(selectedStorageOption);
	};

	// Handle color selection change
	const handleColorChange = (e) => {
		const selectedColorName = e.target.value;
		setSelectedColor(selectedColorName);

		// Find the corresponding image for the selected color
		const selectedColorImage = allImages.find(
			(img) => img.isColorImage && img.colorName === selectedColorName
		);

		if (selectedColorImage) {
			setSelectedImage(selectedColorImage.url);
		}
	};

	return (
		<Container>
			<Navbar />
			<Announcement />
			<Wrapper>
				<ImageWrapper>
					<ImgContainer>
						<Image
							src={selectedImage}
							alt={product.title}
						/>
					</ImgContainer>
					<ThumbnailCarouselContainer>
						<CarouselButton onClick={() => scrollThumbnails("left")}>
							{"<"}
						</CarouselButton>
						<ThumbnailContainer>
							<ThumbnailList ref={thumbnailListRef}>
								{allImages.map((img, idx) => (
									<Thumbnail
										key={idx}
										src={img.url}
										selected={selectedImage === img.url}
										onClick={() => setSelectedImage(img.url)}
									/>
								))}
							</ThumbnailList>
						</ThumbnailContainer>
						<CarouselButton onClick={() => scrollThumbnails("right")}>
							{">"}
						</CarouselButton>
					</ThumbnailCarouselContainer>
				</ImageWrapper>
				<InfoContainer>
					<Title>{product.title}</Title>
					<Desc>{product.desc}</Desc>
					<Price>
						{selectedStorage ? selectedStorage.price : product.price} LKR
					</Price>
					{product.storage && product.storage.length > 0 && (
						<SelectStorage
							onChange={handleStorageChange}
							value={selectedStorage ? selectedStorage.size : ""}>
							{product.storage.map((storage) => (
								<Option
									key={storage.size}
									value={storage.size}>
									{storage.size} GB
								</Option>
							))}
						</SelectStorage>
					)}
					{/* Add color selection dropdown */}
					{product.colors && product.colors.length > 0 && (
						<SelectStorage
							onChange={handleColorChange}
							value={selectedColor || ""}>
							{product.colors.map((color) => (
								<Option
									key={color.name}
									value={color.name}>
									{color.name}
								</Option>
							))}
						</SelectStorage>
					)}
				</InfoContainer>
			</Wrapper>
			<Footer />
		</Container>
	);
};

export default Product;
