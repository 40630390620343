import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../theme";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
	flex: 1;
	margin: 10px;
	min-width: 280px;
	max-width: 320px;
	height: 300px;
	display: flex;
	flex-direction: column;
	background: ${colors.primary};
	backdrop-filter: blur(10px);
	position: relative;
	border-radius: 20px;
	box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	transition: transform 0.3s ease-in-out;

	&:hover {
		transform: translateY(-10px);
	}

	${tablet({ maxWidth: "250px" })}
	${mobile({ maxWidth: "200px" })}
`;

const ImageContainer = styled.div`
	width: 100%;
	height: 200px;
	overflow: hidden;
	border-radius: 10px 20px 0 0;
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const Details = styled.div`
	width: 100%;
	height: 35%;
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	background-color: ${colors.primary};
`;

const Name = styled.h3`
	font-size: 20px;
	color: ${colors.white};
	margin-bottom: 8px;
	text-decoration: none; /* Ensure no underline */
`;

const Price = styled.span`
	font-size: 16px;
	color: ${colors.secondary};
	font-weight: bold;
	text-decoration: none; /* Ensure no underline */
`;

const ProductCard = ({ item }) => {
	// Select the main image for the card (can be the first image or a specific one)
	const mainImage = item.img && item.img.length > 0 ? item.img[0] : null;

	return (
		<Container>
			<Link
				to={`/product/${item._id}`}
				style={{ textDecoration: "none" }}>
				<ImageContainer>
					<Image
						src={mainImage}
						alt={item.title}
					/>
				</ImageContainer>

				<Details>
					<Name>{item.title}</Name>
					<Price>
						{item.price ? `${item.price} LKR` : `${item.storage[0].price} LKR`}
					</Price>
				</Details>
			</Link>
		</Container>
	);
};

export default ProductCard;
