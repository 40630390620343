import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ProductCard from "./ProductCard";
import { colors } from "../theme";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
	padding: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: ${colors.black};
	padding: 3%;
`;

const TitleContainer = styled.div`
	background-color: ${colors.black};
	padding: 3px;
	text-align: center;
`;

const Title = styled.h3`
	margin: 10px;
	font-size: 20px;
	font-weight: 500;
	color: ${colors.white};

	${tablet({ fontSize: "25px" })} /* Adjust font size for tablets */
	${mobile({ fontSize: "20px" })} /* Adjust font size for mobile devices */
`;

const NewestProducts = () => {
	const [newestProducts, setNewestProducts] = useState([]);

	useEffect(() => {
		const fetchNewestProducts = async () => {
			try {
				const res = await axios.get(
					`${process.env.REACT_APP_BASE_URL}/api/products?sortBy=createdAt&order=desc&limit=8`
				);
				console.log("Fetched Products:", res.data); // Log the response data
				setNewestProducts(res.data);
			} catch (err) {
				console.error("Error fetching newest products:", err);
			}
		};
		fetchNewestProducts();
	}, []);

	return (
		<>
			<TitleContainer>
				<Title>Latest in the store</Title>
			</TitleContainer>
			<Container>
				{newestProducts.map((item) => (
					<ProductCard
						item={item}
						key={item.id}
					/>
				))}
			</Container>
		</>
	);
};

export default NewestProducts;
