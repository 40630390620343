// src/components/TikTokEmbed.jsx
import React, { useEffect } from "react";
import styled from "styled-components";
import { tikTokEmbeds } from "../data"; // Import the embed data
import { colors } from "../theme";

const Wrapper = styled.div`
	/* border: 1px solid red; */
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;

	h3 {
		text-align: center;
		margin-bottom: 20px;
		color: ${colors.white};
	}
`;

const Container = styled.div`
	display: flex;
	justify-content: center;
`;

const TikTokVideo = styled.div`
	width: 100%;
	max-width: 605px; /* Adjust width as needed */
	min-width: 325px; /* Adjust min width as needed */
`;

const TikTokEmbed = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://www.tiktok.com/embed.js";
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<Wrapper>
			<h3>Tiktok</h3>
			<Container>
				{tikTokEmbeds.map((video) => (
					<TikTokVideo
						key={video.id}
						dangerouslySetInnerHTML={{ __html: video.embedCode }}
					/>
				))}
			</Container>
		</Wrapper>
	);
};

export default TikTokEmbed;
