import Product from "./pages/Product";
import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import Success from "./pages/Success";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const App = () => {
	const user = useSelector((state) => state.user.currentUser);

	useEffect(() => {
		document.title = "applemart"; // Set your site name here
	}, []);

	return (
		<Router>
			<Routes>
				<Route
					path="/"
					element={<Home />}
				/>
				<Route
					path="/products/:category"
					element={<ProductList />}
				/>
				<Route
					path="/product/:id"
					element={<Product />}
				/>
				<Route
					path="/cart"
					element={<Cart />}
				/>
				<Route
					path="/success"
					element={<Success />}
				/>
				<Route
					path="/login"
					element={
						user ? (
							<Navigate
								to="/"
								replace
							/>
						) : (
							<Login />
						)
					}
				/>
				<Route
					path="/register"
					element={
						user ? (
							<Navigate
								to="/"
								replace
							/>
						) : (
							<Register />
						)
					}
				/>
			</Routes>
		</Router>
	);
};

export default App;
