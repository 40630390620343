import styled from "styled-components";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import Products from "../components/Products";
import Footer from "../components/Footer";
import { mobile } from "../responsive";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { colors } from "../theme";

const Container = styled.div`
	background-color: ${colors.black};
`;

const Title = styled.h1`
	margin: 20px;
`;

const FilterContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Filter = styled.div`
	margin: 20px;
	${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
	color: ${colors.secondary};
`;

const FilterText = styled.span`
	font-size: 20px;
	font-weight: 600;
	margin-right: 20px;
	color: ${colors.white};

	${mobile({ marginRight: "0px" })};
`;

const Select = styled.select`
	padding: 10px;
	margin-right: 20px;
	border-radius: 5px;
	color: ${colors.white};
	background-color: ${colors.primary};
	${mobile({ margin: "10px 0px" })};
`;
const Option = styled.option`
	color: ${colors.white};
	background-color: ${colors.primary};
`;

const ProductList = () => {
	const location = useLocation();
	const cat = location.pathname.split("/")[2]; // Get category from URL
	const [filters, setFilters] = useState({});
	const [sort, setSort] = useState("newest");

	const handleFilters = (e) => {
		const value = e.target.value;
		setFilters({
			...filters,
			[e.target.name]: value,
		});
	};

	return (
		<Container>
			<Navbar />
			<Announcement />

			<FilterContainer>
				{/* <Filter>
					<FilterText>Filter Products:</FilterText>
					<Select
						name="color"
						onChange={handleFilters}>
						<Option disabled>Color</Option>
						<Option value="white">White</Option>
						<Option value="black">Black</Option>
						<Option value="red">Red</Option>
						<Option value="blue">Blue</Option>
						<Option value="yellow">Yellow</Option>
						<Option value="green">Green</Option>
					</Select>
					<Select
						name="size"
						onChange={handleFilters}>
						<Option disabled>Size</Option>
						<Option value="64">64GB</Option>
						<Option value="128">128GB</Option>
						<Option value="256">256GB</Option>
						<Option value="512">512GB</Option>
					</Select>
				</Filter> */}
				<Filter>
					<FilterText>Sort Products:</FilterText>
					<Select onChange={(e) => setSort(e.target.value)}>
						<Option value="newest">Newest</Option>
						<Option value="asc">Price (asc)</Option>
						<Option value="desc">Price (desc)</Option>
					</Select>
				</Filter>
			</FilterContainer>
			<Products
				cat={cat}
				filters={filters}
				sort={sort}
			/>

			<Footer />
		</Container>
	);
};

export default ProductList;
