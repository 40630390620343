// src/components/SocialMedia.jsx
import React from "react";
import styled from "styled-components";

import TikTokEmbed from "./TikTokEmbed";
import FacebookEmbed from "./FacebookEmbed";
import { mobile, tablet } from "../responsive";
import { colors } from "../theme";

const Wrapper = styled.div`
	/* border: 1px solid red; */
	padding-top: 20px;
	text-align: center; /* Center the h2 text */

	/* margin-top: 10px; */
	color: ${colors.white};
	background-color: ${colors.black};
`;

const Container = styled.div`
	/* border: 1px solid blue; */
	display: flex;
	justify-content: space-between;

	${tablet({ flexDirection: "column" })}
	${mobile({ flexDirection: "column" })}
`;

const TikTokContainer = styled.div`
	flex: 1; /* Takes up equal space on the left */
`;

const FacebookContainer = styled.div`
	flex: 1; /* Takes up equal space on the right */
`;

const SocialMedia = () => {
	return (
		<Wrapper>
			<h2>Check out our Social Media</h2>
			<Container>
				<FacebookContainer>
					<FacebookEmbed />
				</FacebookContainer>
				<TikTokContainer>
					<TikTokEmbed />
				</TikTokContainer>
			</Container>
		</Wrapper>
	);
};

export default SocialMedia;
