import React from "react";
import Announcement from "../components/Announcement";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import Slider from "../components/Slider";
import NewestProducts from "../components/NewestProducts";

import ReviewSlider from "../components/ReviewSlider";

import SocialMedia from "../components/SocialMedia";

const Home = () => {
	return (
		<div>
			<Announcement />
			<Navbar />
			<Slider />
			<NewestProducts />
			<ReviewSlider />
			<SocialMedia />
			<Footer />
		</div>
	);
};

export default Home;
