import styled from "styled-components";
import { mobile, tablet, pc } from "../responsive";
import { colors } from "../theme";

const Container = styled.div`
	height: 10px;
	background-color: ${colors.black};
	color: ${colors.secondary};
	display: flex;
	align-items: center;
	justify-content: center;

	font-weight: 500;
	padding: 5px 20px;

	${mobile({
		fontSize: "8px", // Smaller font size on mobile
		display: "none",
	})}

	${tablet({
		fontSize: "12px",
		display: "none", // Slightly larger font on tablets
	})}

	${pc({
		fontSize: "14px", // Original font size for PC
	})}
`;

const Announcement = () => {
	return (
		<Container>
			Super Deal! Free Shipping on all orders. Grab your Apple Device now!
		</Container>
	);
};

export default Announcement;
