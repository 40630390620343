// src/components/WhatsAppContact.jsx
import React from "react";
import styled from "styled-components";
import WhatsAppIcon from "../assets/icons/whatsapp.svg"; // Adjust path as necessary

const IconContainer = styled.a`
	position: fixed;
	bottom: 20px; /* Adjust as needed */
	right: 20px; /* Adjust as needed */
	width: 60px; /* Adjust size as needed */
	height: 60px; /* Adjust size as needed */
	background-color: #25d366; /* WhatsApp green color */
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	z-index: 9999; /* Ensure it's on top of all content */
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #128c7e; /* Darker green on hover */
	}

	img {
		width: 24px; /* Adjust icon size */
		height: 24px; /* Adjust icon size */
	}
`;

const WhatsAppContact = () => {
	return (
		<IconContainer
			href="https://wa.me/+94712963335" // Replace with your WhatsApp link
			target="_blank"
			rel="noopener noreferrer">
			<img
				src={WhatsAppIcon}
				alt="WhatsApp"
			/>
		</IconContainer>
	);
};

export default WhatsAppContact;
